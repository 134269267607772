function attachListenersToShowPasswordButtons() {
    function handleClick(e) {
        const button = e.target;
        const referencedInput = document.getElementById(button.dataset.input);

        if (referencedInput.type === 'password') {
            referencedInput.type = 'text';
            button.innerText = 'Hide';
        } else {
            referencedInput.type = 'password';
            button.innerText = 'Show';
        }
    }

    const buttons = document.querySelectorAll('.show-pass-btn');

    for (let i = 0; i < buttons.length; i += 1) {
        buttons[i].addEventListener('click', handleClick);
    }
}

window.addEventListener(
    'DOMContentLoaded',
    attachListenersToShowPasswordButtons,
);
